import { Heading, Grid, GridItem, Text, BoxProps, Box } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { SkylarkLogoIconBlue } from "@skylark/icons";
import { LauncherItem, Link, Version } from "@skylark/react";
import { useEffect } from "react";
import usePreferredApp from "../hooks/usePreferredApp";
import { Messages } from "../constants/messages";
import { CMS_URL } from "../constants/urls";

export default function Home(): JSX.Element {
  const router = useRouter();
  const [preferredApp, setPreferredApp] = usePreferredApp();

  useEffect(() => {
    if (preferredApp && router?.query?.preferred_app !== "false") {
      void router.push(preferredApp.href);
    }
  }, []);

  const launcherItemStyle: BoxProps = {
    px: {
      xl: "120px",
      md: "80px",
      base: "20px",
    },
    mb: 6,
  };

  const leftColumnBorder: BoxProps = {
    borderRight: {
      lg: "1px solid #E9EBED",
      base: "0",
    },
  };

  const customerName = process.env.NEXT_PUBLIC_CUSTOMER_NAME as string;

  return (
    <Grid
      gridTemplateAreas={{
        lg: `'logo login' 'section1title section2title' 'section1 section2'`,
        base: `'logo login' 'section1title section1title' 'section1 section1' 'section2 section2'`,
      }}
      gridTemplateColumns={{
        lg: `1fr 40%`,
        base: `auto`,
      }}
      gridTemplateRows={{
        lg: "100px 160px 1fr",
        base: "100px 160px 1fr 1fr",
      }}
      h="launcher-h-full-minus-header"
    >
      <GridItem gridArea="logo" p={10} {...leftColumnBorder}>
        <SkylarkLogoIconBlue alt="Skylark Logo" boxSize="24px" />
      </GridItem>
      <GridItem gridArea="login" p={10} textAlign="right">
        <Link data-testid="button-logout" href="/logout">
          {Messages.LAUNCHER_LOGOUT}
        </Link>
      </GridItem>

      <GridItem
        gridArea="section1title"
        {...launcherItemStyle}
        {...leftColumnBorder}
      >
        <Text size="sm">{Messages.LAUNCHER_HEADING}</Text>
        <Heading fontSize="46px" py={1} size="4xl">
          {customerName}
        </Heading>
      </GridItem>

      <GridItem
        gridArea="section1"
        pb={{
          xl: "80px",
          base: "0",
        }}
        {...leftColumnBorder}
      >
        <LauncherItem
          data-testid="button-cms"
          description={Messages.LAUNCHER_ITEM_CMS_DESCRIPTION}
          href={CMS_URL}
          icon="skylarkClassic"
          title={Messages.LAUNCHER_ITEM_CMS}
          onClick={() => setPreferredApp("classic-cms")}
          {...launcherItemStyle}
        />
      </GridItem>

      <GridItem
        borderTop={{
          lg: "0",
          base: "1px solid #E9EBED",
        }}
        gridArea="section2"
        pt={{
          lg: "0",
          sm: "40px",
          base: "80px",
        }}
      >
        <LauncherItem
          data-testid="button-api"
          description={Messages.LAUNCHER_ITEM_API_DESCRIPTION}
          href="https://developers.skylarkplatform.com/api/skylark_api.html"
          icon="skylarkApi"
          isExternal
          title={Messages.LAUNCHER_ITEM_API}
          variant="secondary"
          {...launcherItemStyle}
        />
        <LauncherItem
          data-testid="button-user-management"
          description={Messages.LAUNCHER_ITEM_USER_MANAGEMENT_DESCRIPTION}
          href="/users"
          icon="userManagement"
          title={Messages.LAUNCHER_ITEM_USER_MANAGEMENT}
          variant="secondary"
          {...launcherItemStyle}
        />
      </GridItem>
      <Version
        bottom={{ base: 4, md: 6 }}
        left={{ base: 4, md: 6 }}
        position="absolute"
      />
    </Grid>
  );
}
